<mat-toolbar color="primary " class="mat-elevation-z4"></mat-toolbar>
<div class="container" *transloco="let trl">
  <div class="row">
    <div class="col text-center d-flex vh-100 justify-content-center align-items-center">
      <div class="d-flex">
        <div class="mat-headline-1">404</div>
        <mat-divider vertical class="mx-4 h-inherit align-self-stretch"></mat-divider>
        <div>
          <div class="mat-headline-2 mb-3">{{ trl("common.oops") | ucfirst }}</div>
          <div class="mat-subtitle-1">
            {{ trl("common._sentences.Page_not_found") }}
          </div>
          <a [href]="appSettingsService.portalAppUrl" mat-raised-button color="primary">{{ trl("common._sentences.Page_not_found_link") }}</a>
        </div>
      </div>
    </div>
  </div>
</div>
