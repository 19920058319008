import { EnvironmentProviders, Injectable, Provider } from '@angular/core';
import { FormGroupDirective, NgForm, UntypedFormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { TranslocoService } from '@jsverse/transloco';
import { PaginatorIntlService } from './paginator-intl.service';

@Injectable()
export class DirtyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && form && form.submitted);
  }
}

export function materialConfig(): Provider | EnvironmentProviders {
  return [
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration: 5500,
      },
    },
    {
      provide: MAT_TABS_CONFIG,
      useValue: {
        stretchTabs: false,
      },
    },
    {
      provide: MatPaginatorIntl,
      useFactory: (translocoService: TranslocoService) => new PaginatorIntlService(translocoService),
      deps: [TranslocoService],
    },
    { provide: ErrorStateMatcher, useClass: DirtyErrorStateMatcher },
  ];
}
